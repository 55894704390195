// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-box {
  border: solid black 1px;
}
.name-box--error {
  border: solid red 1px;
}

.org-box {
  border: solid black 1px;
}
.org-box--error {
  border: solid red 1px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Device/CreateDevice.scss"],"names":[],"mappings":"AAEA;EACI,uBAAA;AADJ;AAEI;EACI,qBAAA;AAAR;;AAIA;EACI,uBAAA;AADJ;AAEI;EACI,qBAAA;AAAR","sourcesContent":["// wooooooooooImBouttaMakeAStylesheetForMyself\n\n.name-box {\n    border: solid black 1px;\n    &--error {\n        border: solid red 1px;\n    }\n}\n\n.org-box {\n    border: solid black 1px;\n    &--error {\n        border: solid red 1px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
