// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
    Button Base Style
    When using, manually define:
    (1) any margins (i.e., margin-top: 10px)
    (2) width (i.e., width: 100%)
*/`, "",{"version":3,"sources":["webpack://./src/_variables.scss"],"names":[],"mappings":"AAsBA;;;;;CAAA","sourcesContent":["// Main color scheme\n$primary-light-1: #8ecae6;\n$primary-light-2: #219ebc;\n$primary-dark: #023047;\n$secondary-light-1: #ffb703;\n$secondary-light-2: #fb8500; \n$tertiary-light-1: #f7ccac;\n$background: #f0f0f0;\n$accent-light: #e3e3e3;\n$accent-dark: #e6e6e6;\n$accent-darker: #bfbfbf;\n$error: red;\n\n// Accessibility \n$focus: #0060df;\n$focus-styling: solid 2px $focus !important;\n\n// Fonts\n$font-normal: \"Poppins\";\n$font-semibold: \"Poppins-SB\";\n$font-bold: \"Poppins-EB\";\n\n/*\n    Button Base Style\n    When using, manually define:\n    (1) any margins (i.e., margin-top: 10px)\n    (2) width (i.e., width: 100%)\n*/\n@mixin button-base--super {\n    border-radius: 5px;\n    border: 0;\n    font-family: $font-semibold;\n    font-size: 1em;\n    outline: none;\n    padding: 5px 10px;\n    text-transform: uppercase;\n\n    &:focus-visible {\n        outline: focus-styling;\n    }\n}\n\n@mixin button-base {\n    @include button-base--super; \n    background-color: $secondary-light-1;\n    color: white;\n    cursor: pointer;\n}\n\n@mixin button-base--disabled {\n    @include button-base--super;\n    background-color: gray;\n    color: white;\n    cursor: not-allowed;\n}\n\n:export {\n    primaryLight1: $primary-light-1;\n    primaryLight2: $primary-light-2;\n    secondaryLight1: $secondary-light-1;\n    secondaryLight2: $secondary-light-2;\n    tertiaryLight1: $tertiary-light-1;\n    background: $background;\n    accentLight: $accent-light;\n    accentDark: $accent-dark;\n    accentDarker: $accent-darker;\n    error: $error;\n    fontNormal: $font-normal;\n    fontSemibold: $font-semibold;\n    fontBold: $font-bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryLight1": `#8ecae6`,
	"primaryLight2": `#219ebc`,
	"secondaryLight1": `#ffb703`,
	"secondaryLight2": `#fb8500`,
	"tertiaryLight1": `#f7ccac`,
	"background": `#f0f0f0`,
	"accentLight": `#e3e3e3`,
	"accentDark": `#e6e6e6`,
	"accentDarker": `#bfbfbf`,
	"error": `red`,
	"fontNormal": `"Poppins"`,
	"fontSemibold": `"Poppins-SB"`,
	"fontBold": `"Poppins-EB"`
};
export default ___CSS_LOADER_EXPORT___;
