import plz from "./../../../assets/image.png";

const Help = () =>
{
    return (
        <div id="provider-data">
            <img src={plz} alt="Help me"/>

        </div>
    );
}

export default Help;